export const CYAN_000 = '#E0FCFF';
export const CYAN_100 = '#BEF8FD';
export const CYAN_200 = '#87EAF2';
export const CYAN_300 = '#54D1DB';
export const CYAN_400 = '#38BEC9';
export const CYAN_500 = '#2CB1BC';
export const CYAN_600 = '#14919B';
export const CYAN_700 = '#0E7C86';
export const CYAN_800 = '#0A6C74';
export const CYAN_900 = '#044E54';

export const GREY_000 = '#FAF9F7';
export const GREY_100 = '#E8E6E1';
export const GREY_200 = '#D3CEC4';
export const GREY_300 = '#B8B2A7';
export const GREY_400 = '#A39E93';
export const GREY_500 = '#857F72';
export const GREY_600 = '#625D52';
export const GREY_700 = '#504A40';
export const GREY_800 = '#423D33';
export const GREY_900 = '#27241D';

export const BLUE_000 = '#DCEEFB';
export const BLUE_100 = '#B6E0FE';
export const BLUE_200 = '#84C5F4';
export const BLUE_300 = '#62B0E8';
export const BLUE_400 = '#4098D7';
export const BLUE_500 = '#2680C2';
export const BLUE_600 = '#186FAF';
export const BLUE_700 = '#0F609B';
export const BLUE_800 = '#0A558C';
export const BLUE_900 = '#003E6B';

export const RED_000 = '#FFEEEE';
export const RED_100 = '#FACDCD';
export const RED_200 = '#F29B9B';
export const RED_300 = '#E66A6A';
export const RED_400 = '#D64545';
export const RED_500 = '#BA2525';
export const RED_600 = '#A61B1B';
export const RED_700 = '#911111';
export const RED_800 = '#780A0A';
export const RED_900 = '#610404';

export const YELLOW_000 = '#FFFAEB';
export const YELLOW_100 = '#FCEFC7';
export const YELLOW_200 = '#F8E3A3';
export const YELLOW_300 = '#F9DA8B';
export const YELLOW_400 = '#F7D070';
export const YELLOW_500 = '#E9B949';
export const YELLOW_600 = '#C99A2E';
export const YELLOW_700 = '#A27C1A';
export const YELLOW_800 = '#7C5E10';
export const YELLOW_900 = '#513C06';

export const GREEN_000 = '#EFFCF6';
export const GREEN_100 = '#C6F7E2';
export const GREEN_200 = '#8EEDC7';
export const GREEN_300 = '#65D6AD';
export const GREEN_400 = '#3EBD93';
export const GREEN_500 = '#27AB83';
export const GREEN_600 = '#199473';
export const GREEN_700 = '#147D64';
export const GREEN_800 = '#0C6B58';
export const GREEN_900 = '#014D40';

export const PRIMARY_000 = CYAN_000;
export const PRIMARY_100 = CYAN_100;
export const PRIMARY_200 = CYAN_200;
export const PRIMARY_300 = CYAN_300;
export const PRIMARY_400 = CYAN_400;
export const PRIMARY_500 = CYAN_500;
export const PRIMARY_600 = CYAN_600;
export const PRIMARY_700 = CYAN_700;
export const PRIMARY_800 = CYAN_800;
export const PRIMARY_900 = CYAN_900;
