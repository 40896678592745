import { rem } from 'polished';

function lineHeight(size) {
    let lineHeight;
    if (size < 20) {
        lineHeight = 1.75;
    } else if (size < 24) {
        lineHeight = 1.625;
    } else if (size < 32) {
        lineHeight = 1.375;
    } else {
        lineHeight = 1.125;
    }
    return lineHeight + 'em';
}

function getNormalPrimaryStyles(size) {
    return {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: rem(size),
        lineHeight: lineHeight(size),
        fontWeight: 'normal'
    };
}
function getBoldPrimaryStyles(size) {
    return {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: rem(size),
        lineHeight: lineHeight(size),
        fontWeight: 'bold'
    };
}
function getNormalSecondaryStyles(size) {
    return {
        fontFamily: 'roboto, sans-serif',
        fontSize: rem(size),
        lineHeight: lineHeight(size),
        fontWeight: 'normal'
    };
}
function getBoldSecondaryStyles(size) {
    return {
        fontFamily: 'roboto, sans-serif',
        fontSize: rem(size),
        lineHeight: lineHeight(size),
        fontWeight: 'bold'
    };
}

export const PRIMARY_12 = getNormalPrimaryStyles(12);
export const PRIMARY_14 = getNormalPrimaryStyles(14);
export const PRIMARY_16 = getNormalPrimaryStyles(16);
export const PRIMARY_18 = getNormalPrimaryStyles(18);
export const PRIMARY_20 = getNormalPrimaryStyles(20);
export const PRIMARY_24 = getNormalPrimaryStyles(24);
export const PRIMARY_30 = getNormalPrimaryStyles(30);
export const PRIMARY_36 = getNormalPrimaryStyles(36);
export const PRIMARY_48 = getNormalPrimaryStyles(48);
export const PRIMARY_60 = getNormalPrimaryStyles(60);
export const PRIMARY_72 = getNormalPrimaryStyles(72);

export const PRIMARY_BOLD_12 = getBoldPrimaryStyles(12);
export const PRIMARY_BOLD_14 = getBoldPrimaryStyles(14);
export const PRIMARY_BOLD_16 = getBoldPrimaryStyles(16);
export const PRIMARY_BOLD_18 = getBoldPrimaryStyles(18);
export const PRIMARY_BOLD_20 = getBoldPrimaryStyles(20);
export const PRIMARY_BOLD_24 = getBoldPrimaryStyles(24);
export const PRIMARY_BOLD_30 = getBoldPrimaryStyles(30);
export const PRIMARY_BOLD_36 = getBoldPrimaryStyles(36);
export const PRIMARY_BOLD_48 = getBoldPrimaryStyles(48);
export const PRIMARY_BOLD_60 = getBoldPrimaryStyles(60);
export const PRIMARY_BOLD_72 = getBoldPrimaryStyles(72);

export const SECONDARY_12 = getNormalSecondaryStyles(12);
export const SECONDARY_14 = getNormalSecondaryStyles(14);
export const SECONDARY_16 = getNormalSecondaryStyles(16);
export const SECONDARY_18 = getNormalSecondaryStyles(18);
export const SECONDARY_20 = getNormalSecondaryStyles(20);
export const SECONDARY_24 = getNormalSecondaryStyles(24);
export const SECONDARY_30 = getNormalSecondaryStyles(30);
export const SECONDARY_36 = getNormalSecondaryStyles(36);
export const SECONDARY_48 = getNormalSecondaryStyles(48);
export const SECONDARY_60 = getNormalSecondaryStyles(60);
export const SECONDARY_72 = getNormalSecondaryStyles(72);

export const SECONDARY_BOLD_12 = getBoldSecondaryStyles(12);
export const SECONDARY_BOLD_14 = getBoldSecondaryStyles(14);
export const SECONDARY_BOLD_16 = getBoldSecondaryStyles(16);
export const SECONDARY_BOLD_18 = getBoldSecondaryStyles(18);
export const SECONDARY_BOLD_20 = getBoldSecondaryStyles(20);
export const SECONDARY_BOLD_24 = getBoldSecondaryStyles(24);
export const SECONDARY_BOLD_30 = getBoldSecondaryStyles(30);
export const SECONDARY_BOLD_36 = getBoldSecondaryStyles(36);
export const SECONDARY_BOLD_48 = getBoldSecondaryStyles(48);
export const SECONDARY_BOLD_60 = getBoldSecondaryStyles(60);
export const SECONDARY_BOLD_72 = getBoldSecondaryStyles(72);
