import React from 'react';
import ReactDOM from 'react-dom';

import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

import App from './App';

import { GREY_000, GREY_900 } from './styles/colors';

const GlobalStyles = createGlobalStyle`
    ${normalize()}

    html {
        font-family: sans-serif;
        box-sizing: border-box;
        color: ${GREY_900};
    }

    body {
        background-color: ${GREY_000};
    }

    * {
        box-sizing: inherit;
    }
`;

ReactDOM.render(
    <>
        <GlobalStyles />
        <App />
    </>,
    document.getElementById('root')
);
