export const ancestries = {
    Bugbear: {
        name: 'Bugbear',
        attack: 2,
        power: 0,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Martial']
    },
    Dragonborn: {
        name: 'Dragonborn',
        attack: 2,
        power: 2,
        defense: 1,
        toughness: 1,
        morale: 1,
        traits: ['Courageous']
    },
    Dwarf: {
        name: 'Dwarf',
        attack: 3,
        power: 1,
        defense: 1,
        toughness: 1,
        morale: 2,
        traits: ['Stalwart']
    },
    Elf: {
        name: 'Elf',
        attack: 2,
        power: 0,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Eternal']
    },
    'Elf (Winged)': {
        name: 'Elf (Winged)',
        attack: 1,
        power: 1,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Eternal']
    },
    Ghoul: {
        name: 'Ghoul',
        attack: -1,
        power: 0,
        defense: 2,
        toughness: 2,
        morale: 0,
        traits: ['Undead', 'Horrify', 'Ravenous']
    },
    Gnoll: {
        name: 'Gnoll',
        attack: 2,
        power: 0,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Frenzy']
    },
    Gnome: {
        name: 'Gnome',
        attack: 1,
        power: -1,
        defense: 1,
        toughness: -1,
        morale: 1,
        traits: []
    },
    Goblin: {
        name: 'Goblin',
        attack: -1,
        power: -1,
        defense: 1,
        toughness: -1,
        morale: 0,
        traits: []
    },
    Hobgoblin: {
        name: 'Hobgoblin',
        attack: 2,
        power: 0,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Bred for War', 'Martial']
    },
    Human: {
        name: 'Human',
        attack: 2,
        power: 0,
        defense: 0,
        toughness: 0,
        morale: 1,
        traits: ['Courageous']
    },
    Kobold: {
        name: 'Kobold',
        attack: -1,
        power: -1,
        defense: 1,
        toughness: -1,
        morale: -1,
        traits: []
    },
    Lizardfolk: {
        name: 'Lizardfolk',
        attack: 2,
        power: 1,
        defense: -1,
        toughness: 1,
        morale: 1,
        traits: ['Amphibious']
    },
    Ogre: {
        name: 'Ogre',
        attack: 0,
        power: 2,
        defense: 0,
        toughness: 2,
        morale: 1,
        traits: ['Brutal']
    },
    Orc: {
        name: 'Orc',
        attack: 2,
        power: 1,
        defense: 1,
        toughness: 1,
        morale: 2,
        traits: ['Savage']
    },
    Skeleton: {
        name: 'Skeleton',
        attack: -2,
        power: -1,
        defense: 1,
        toughness: 1,
        morale: 1,
        traits: ['Undead', 'Mindless']
    },
    Treant: {
        name: 'Treant',
        attack: 0,
        power: 2,
        defense: 0,
        toughness: 2,
        morale: 0,
        traits: ['Twisting Roots', 'Rock-Hurler']
    },
    Troll: {
        name: 'Troll',
        attack: 0,
        power: 2,
        defense: 0,
        toughness: 2,
        morale: 0,
        traits: ['Regenerate']
    },
    Zombie: {
        name: 'Zombie',
        attack: -2,
        power: 0,
        defense: 2,
        toughness: 2,
        morale: 2,
        traits: ['Undead', 'Mindless']
    }
};

export const ancestryIds = Object.keys(ancestries).sort();
